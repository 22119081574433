import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NameValue } from '../../shared/models/dto/general';
import { Endpoint } from '../../shared/models/dto/endpoint';
import AuthStore from '../../stores/auth-store';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class EditCreateEndpointDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: string;
    @Prop({ default: false }) readonly show: boolean;

    refs = this.$refs as any;
    loading: boolean = true;
    valid: boolean = true;
    isEdit: boolean = false;
    formTitle: string = '';
    errors: NameValue[] = [];
    endpointTypes: NameValue[] = [];

    endpoint: Endpoint = {
        displayName: null,
        endpointType: null,
        endpointServer: null,
        companyId: null
    };

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            if (this.refs.form) {
                this.refs.form.reset();
            }

            this.endpoint = {
                displayName: null,
                endpointType: null,
                endpointServer: null,
                companyId: AuthStore.getUser().companyId
            };

            this.loading = true;
            this.isEdit = !!this.id;
            this.formTitle = `${this.isEdit ? this.t('Edit') : this.t('New')} ${this.t('Endpoint').toLowerCase()}`;

            this.endpointTypes = [
                {
                    name: 'OPC UA intern', value: 'OpcUAInternal'
                },
                {
                    name: 'OPC UA extern', value: 'OpcUAExternal'
                },
                // TODO aanzetten als het beschikbaar is
                //{
                //    name: 'OPC DA', value: 'OpcDA'
                //},
                //{
                //    name: 'Modbus', value: 'Modbus'
                //},
                //{
                //    name: 'Profinet', value: 'Profinet'
                //}
            ];

            if (this.isEdit) {
                await this.getEndpoint();
            }
            
            this.loading = false;
        }
    }

    async getEndpoint() {
        this.authService.get<Endpoint>(
            `/api/endpoints/${this.id}`).then(async (response) => {
                this.endpoint = response.content;

                this.endpoint.endpointType = this.endpoint.endpointType;
        });
    }

    save() {
        if (this.refs.form.validate()) {
            this.errors = [];
       
            const key: string = this.endpoint.id ? this.endpoint.id.toString() : '';

            this.authService.postOrPut<void>(
                `/api/endpoints/`,
                this.endpoint,
                key
            ).then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('Successful'));
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });
        }
    }

    close() {
        this.$emit('completed', false);
    }
}